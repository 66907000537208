import {
	IconMail,
	IconBrandWhatsapp,
} from "@tabler/icons";

export default {

	FETCH_INIT: "fetch_init",
	FETCH_LOADING: "fetch_loading",
	FETCH_ERROR: "fetch_error",
	FETCH_RESULT: "fetch_result",
	top_menu: {
		title_menu: [{
			text: "help@femaledaily.com",
			link: {
				desktop: "mailto:help@femaledaily.com",
			},
			icon: <IconMail style={{ marginRight: "10.25px", color: "#000" }} stroke="1.5" size="20px" />,
		},
		{
			text: "+62 857 7289 9179",
			link: {
				desktop: "https://wa.me/6285772899179",
			},
			icon: <IconBrandWhatsapp style={{ marginRight: "10.25px", color: "#000" }} stroke="1.5" size="20px" />,
		}],
		menus: [
			{
				text: "FEMALE DAILY",
				link: process.env.NEXT_PUBLIC_ROOT_HOME,
				id: null,
				new: false,
			},
			{
				text: "REVIEWS",
				link: process.env.NEXT_PUBLIC_REVIEWS_DOMAIN,
				id: null,
				new: false,
			},
			{
				text: "ARTICLES",
				link: process.env.NEXT_PUBLIC_EDITORIAL_DOMAIN,
				id: null,
				new: false,
			},
			{
				text: "FEMALE DAILY STUDIO",
				link: process.env.NEXT_PUBLIC_ECM_DOMAIN,
				id: null,
				new: false,
			},
			{
				text: "TALK",
				link: process.env.NEXT_PUBLIC_TALK_DOMAIN,
				id: null,
				new: false,
			},
			{
				text: "TRY AND REVIEW",
				link: `${process.env.NEXT_PUBLIC_ROOT_HOME}/try-and-review`,
				id: null,
				new: false,
			},
		],
	},
	mega_menu: [
		{
			name: "HOME",
			url: process.env.NEXT_PUBLIC_ROOT_DOMAIN,
			slug: "home",
			id: "id_home_editorial",
			children: [],
		},
		{
			name: "READ",
			url: null,
			slug: "read",
			id: "id_home_read",
			children: [],
		},
		{
			name: "VIDEO",
			url: "/blog/category/video-blog",
			slug: "video-blog",
			id: "id_home_video",
			children: [],
		},
		{
			name: "QUIZ",
			url: "/blog/category/quiz",
			slug: "quiz",
			id: "id_home_quiz",
			children: [],
		},
		{
			name: "PODCAST",
			url: "/blog/category/podcast",
			slug: "podcast",
			id: "id_home_podcast",
			children: [],
		},
	],
	config_sidebar: [
		{
			name: "REVIEWS",
			value: "reviews",
			pathname: process.env.NEXT_PUBLIC_REVIEWS_DOMAIN,
			query: "order=newest",
			visible: true,
			new: false,
		},
		{
			name: "SHOP AT BEAUTY STUDIO",
			value: "studio",
			pathname: process.env.NEXT_PUBLIC_ECM_DOMAIN,
			query: "page=1",
			visible: true,
			new: true,
		},
		{
			name: "TALK",
			value: "talk",
			pathname: process.env.NEXT_PUBLIC_TALK_DOMAIN,
			query: "",
			visible: true,
			new: false,
		},
		{
			name: "TRY & REVIEW",
			value: "try-and-review",
			pathname: `${process.env.NEXT_PUBLIC_ROOT_HOME}/try-and-review`,
			query: "",
			visible: true,
			new: false,
		},
	],
	custom_sidebar: [
		{
			name: "EVENT NAME",
			value: "event-name",
			pathname: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/event-name`,
			query: "",
			visible: true,
			new: false,
		},
		{
			name: "BEAUTY BUNDLE",
			value: "beauty-bundle",
			pathname: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/beauty-bundle`,
			query: "",
			visible: true,
			new: false,
		},
		{
			name: "TRAVEL SIZE",
			value: "travel-size",
			pathname: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/travel-size`,
			query: "",
			visible: true,
			new: false,
		},
		{
			name: "ON SALE",
			value: "on-sale",
			pathname: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/on-sale`,
			query: "",
			visible: true,
			new: false,
		},
	],

};
